import {
  OwnUpBody,
  OwnUpBodyMedium,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper
} from '@rategravity/own-up-component-library-new';
import { format } from 'date-fns';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import {
  processBlogPostFromHtml,
  processBlogPostFromMarkdown
} from '../../cms/helpers/create-pages';
import { kebab } from '../../module/kebab';
import { AuthorDetails, AuthorJson, AuthorProps } from '../author-details';
import {
  BlogPostWrapper,
  CategoriesWrapper,
  DateWrapper,
  Headline,
  ImageWrapper,
  Link,
  MetadataWrapper
} from './components';

export type BlogPostProps = {
  id: string;
  title: string;
  author: string;
  tags: string[];
  img: IGatsbyImageData | string;
  imgAlt?: string;
  publishedDate: Date;
  body: string | JSX.Element;
  bodyType: 'html' | 'markdown';
};

export const BlogPost = ({
  id,
  title,
  author,
  tags = [],
  img,
  imgAlt,
  publishedDate,
  body,
  bodyType = 'html'
}: BlogPostProps) => {
  const renderedImg =
    typeof img === 'string' ? (
      // Editor preview (no graphql/sharp)
      <img
        src={img}
        alt={imgAlt || title}
        // Emulate image cropping applied in the page query
        style={{
          objectFit: 'cover',
          objectPosition: 'center center',
          width: '100%',
          maxWidth: 1120,
          maxHeight: 500
        }}
      />
    ) : (
      // Rendered post
      <GatsbyImage image={img} alt={imgAlt || title} style={{ margin: 'auto', display: 'block' }} />
    );

  const bodyProcessor = bodyType === 'html' ? processBlogPostFromHtml : processBlogPostFromMarkdown;

  const renderedBody = bodyProcessor(body as Parameters<typeof bodyProcessor>[0], id);
  const [authorProps, setAuthorProps] = useState<AuthorProps | undefined>();

  useEffect(() => {
    const fetchAuthorProps = async () => {
      const details = ((await import('../../../content/authors.json')) as AuthorJson).bios.find(
        (bio) => bio.name === author
      );
      setAuthorProps(details);
    };
    fetchAuthorProps().catch(console.error);
  }, [author]);

  return (
    <React.Fragment>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridItem xs>
            <Headline variant="h1">{title}</Headline>
            <MetadataWrapper>
              <CategoriesWrapper>
                {tags.map((tag, index) => (
                  <Link to={`/tag/${kebab(tag)}`} key={index}>
                    <OwnUpBodyMedium variant="body1">{tag}</OwnUpBodyMedium>
                  </Link>
                ))}
              </CategoriesWrapper>
              <OwnUpBodyMedium variant="body1">{author}</OwnUpBodyMedium>
              <DateWrapper>
                <OwnUpBody variant="body1">{format(publishedDate, 'dd MMM yyyy')}</OwnUpBody>
              </DateWrapper>
            </MetadataWrapper>
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
      <ImageWrapper>{renderedImg}</ImageWrapper>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridOffset xs={0} md />
          <BlogPostWrapper xs={12} md={8}>
            {renderedBody}
            <AuthorDetails {...{ authorProps }} />
          </BlogPostWrapper>
          <OwnUpGridOffset xs={0} md />
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </React.Fragment>
  );
};
