import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { BackToHomepage } from '../../components/back-to-homepage';
import { BlogPost } from '../../components/blog-post';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';

interface QueryData {
  markdownRemark: {
    id: string;
    html: string;
    frontmatter: {
      title: string;
      author: string;
      tags: string[];
      img: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      imgAlt: string;
      publishedDate: string;
      description: string;
    };
  };
}

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        author
        description
        tags
        img {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1120
              height: 500
              quality: 90
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
        imgAlt
        publishedDate
      }
    }
  }
`;

interface PageProps {
  data: QueryData;
}

export const Page = ({ data }: PageProps) => {
  const {
    markdownRemark: {
      id,
      html,
      frontmatter: {
        title,
        author,
        tags,
        imgAlt,
        description,
        img: {
          childImageSharp: { gatsbyImageData: img }
        },
        publishedDate
      }
    }
  } = data;

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <BackToHomepage />
        <BlogPost
          title={title}
          author={author}
          tags={tags || []}
          img={img}
          id={id}
          imgAlt={imgAlt}
          publishedDate={new Date(publishedDate)}
          body={html}
          bodyType="html"
        />
      </Layout>
    </>
  );
};

//eslint-disable-next-line import/no-default-export
export default Page;
