import { MOSS_100, PropsWithTheme } from '@rategravity/own-up-component-library-new';
import styled from 'styled-components';

export const AuthorDetailsWrapper = styled.div`
  background-color: ${MOSS_100};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  padding: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: start;
  }
`;

export const AuthorDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

export const AuthorDetailsImage = styled.img`
  width: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(14.5)}px`};
  height: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(14.5)}px`};
  object-fit: cover;
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;
