import { useTrackingInstance } from '@rategravity/1pt-lib';
import { IconLink, OwnUpGridWrapper } from '@rategravity/own-up-component-library-new';
import { ArrowLeftIcon } from '@rategravity/own-up-component-library-new/icon-library/system-icons/standard-icons/arrow-left';
import { Link } from 'gatsby';
import React from 'react';

export const BackToHomepage = () => {
  const trackingInstance = useTrackingInstance();
  const onClick = () => trackingInstance.track('Back To Homepage Clicked', {}, 'v1');

  return (
    <OwnUpGridWrapper>
      <IconLink
        link="/"
        component={Link}
        to="/"
        iconPlacement="left"
        icon={<ArrowLeftIcon />}
        onClick={onClick}
      >
        Back to homepage
      </IconLink>
    </OwnUpGridWrapper>
  );
};
