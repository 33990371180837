import { useTrackingInstance } from '@rategravity/1pt-lib';
import { PropsWithTheme } from '@rategravity/own-up-component-library-new';
import React from 'react';
import styled from 'styled-components';

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const WWW = 'www.ownup.com';
const MY = 'my.ownup.com';

const Link = styled.a`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.underlineLinkColors.linkFontColor};
  text-decoration: none;
`;

const getDestination = (href?: string) => {
  if (!href) {
    return `https://${process.env.GATSBY_WWW || WWW}/onboarding/get-started`;
  }

  if (href[0] === '/') {
    return href;
  }

  try {
    const { hostname } = new URL(href);

    switch (hostname) {
      case WWW:
        return href.replace(WWW, process.env.GATSBY_WWW || WWW);
      case MY:
        return href.replace(MY, process.env.GATSBY_MY || MY);
      default:
        href;
    }
  } catch (err) {
    console.log(err);
    return href;
  }
};

const LinkWithTracking = (id: string) => {
  const Anchor = (props: AnchorProps, children: React.ReactNode) => {
    const trackingInstance = useTrackingInstance();
    const destination = getDestination(props.href);

    const onClick = (e: React.MouseEvent) => {
      e.preventDefault();

      // This is flaky because navigation events interfere with tracking,
      // but we'll implement it here as a placeholder for a more robust tracking function.
      trackingInstance.track(
        'Blog Post Clicked',
        {
          pageNavigatedTo: { url: destination }
        },
        'v1'
      );

      // Hope that it only takes 300ms for the 1PT server to respond.
      // Wait time guided by Mixpanel's definition for a similar tracking function:
      // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack_links
      setTimeout(() => {
        if (typeof window !== 'undefined' && destination) {
          const urlString =
            destination[0] === '/' ? `${window.location.origin}${destination}` : destination;

          // Ideally, we wouldn't need to add a utm parameter,
          // but until we have more robust tracking, it's good to do this just in case.
          const url = new URL(urlString);
          url.searchParams.append('utm_referrer', id);

          window.location.href = url.href;
        }
      }, 300);

      return false;
    };

    return (
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <Link {...props} href={props.href} onClick={onClick} target="_blank">
        {children}
      </Link>
    );
  };

  return Anchor;
};

export const replaceAnchorTag = (id: string) => ({
  a: LinkWithTracking(id)
});
