import {
  OwnUpHeadlineBook,
  OwnUpSmallSubheadlineMedium,
  OwnUpSubheadlineMedium
} from '@rategravity/own-up-component-library-new';
import React, { ReactNode } from 'react';

const OwnUpH1Wrapper = (props: Record<string, any>, children: ReactNode) => (
  <OwnUpHeadlineBook variant="h1" {...props}>
    {children}
  </OwnUpHeadlineBook>
);

const OwnUpH2Wrapper = (props: Record<string, any>, children: ReactNode) => (
  <OwnUpSubheadlineMedium
    variant="h2"
    {...props}
    style={{ fontSize: '2rem', lineHeight: '2.5rem' }}
  >
    {children}
  </OwnUpSubheadlineMedium>
);

const OwnUpH3Wrapper = (props: Record<string, any>, children: ReactNode) => (
  <OwnUpSmallSubheadlineMedium
    variant="h3"
    {...props}
    style={{ fontSize: '1.5rem', lineHeight: '2rem' }}
  >
    {children}
  </OwnUpSmallSubheadlineMedium>
);

export const replaceHeadingsMap = {
  h1: OwnUpH1Wrapper,
  h2: OwnUpH2Wrapper,
  h3: OwnUpH3Wrapper
};
// c.f. https://github.com/netlify/netlify-cms/blob/master/packages/netlify-cms-locales/src/en/index.js
export const updateHeadingNames = (lang: Record<string, any>) => {
  lang.editor.editorWidgets.headingOptions = {
    headingOne: 'Headline',
    headingTwo: 'Subheadline',
    headingThree: 'Small Subheadline'
  };
  return lang;
};
