import * as editorExtensions from '../../cms/editor-extensions';
import { EditorExtensions } from '../../cms/editor-extensions/types';

export const EditorExtension: (props: any) => JSX.Element | null = ({
  extensionname: extensionName,
  ...extensionProps
}) => {
  if (extensionName in editorExtensions) {
    return (editorExtensions as EditorExtensions)[extensionName].component(extensionProps);
  }
  console.warn(
    `Ignoring placeholder for unknown extension type '${extensionName}'`,
    editorExtensions
  );
  return null;
};
