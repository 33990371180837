import React, { FormEventHandler, useState } from 'react';
import {
  EditorExtensionComponent,
  EditorExtensionComponentProps,
  EditorExtensionProps
} from './types';

interface EmailCollectorDataEventTarget extends HTMLFormElement {
  email: {
    value: string;
  };
}

interface EmailCollectorProps extends EditorExtensionComponentProps {
  submiturl: string | ((request: XMLHttpRequest) => void);
  context?: 'preview';
}

/**
 * Creates a form for soliciting an email address
 *
 * @param {string} submiturl - the form's target url
 * @param {string} [context] set to 'preview' to disable form submission
 */
export const EmailCollector: EditorExtensionComponent<EmailCollectorProps> = ({
  // NOTE: field names must be all lower case, no camelCasing!
  submiturl,
  context
}: EmailCollectorProps) => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit: FormEventHandler<EmailCollectorDataEventTarget> = (event) => {
    event.preventDefault();
    const emailCollectorData = new FormData(
      // TODO: the `as` here shouldn't be necessary?
      event.target as EmailCollectorDataEventTarget
    );
    const request = new XMLHttpRequest();
    // This is to let storybook or tests pass in a function that reports
    // details on the request that would be sent without sending it.
    // TODO: storybook and tests...
    if (submiturl instanceof Function) {
      submiturl(request);
    } else {
      request.open('POST', submiturl);
      request.send(emailCollectorData);
    }
    setSubmitted(true);
  };

  // It looks like netlify cms removes event handlers in
  // preview components, so clicking Submit will cause
  // the form to actually try to POST. Until a workaround
  // is found, we need to disable it in preview.
  const title =
    context === 'preview'
      ? {
          // the "title" attribute sets the tooltip value for inputs
          title: `Form submission is disabled in preview. Target: ${submiturl}`
        }
      : undefined;
  const disabled = context === 'preview';

  const thankYou = <div>THANK YOU</div>;

  return submitted ? (
    thankYou
  ) : (
    <form className="emailCollector" onSubmit={handleSubmit}>
      <input
        type="email"
        name="email"
        size={22}
        placeholder={`Enter your email`}
        disabled={disabled}
        {...title}
      />
      <input type="submit" disabled={disabled} {...title} />
    </form>
  );
};

export const emailCollector: EditorExtensionProps<EmailCollectorProps> = {
  id: 'emailCollector', // must match exported name
  label: 'Email Form',
  // Each of the fields below becomes an input of the form presented to the
  // user when adding a new email form in the editor. For a complete list of
  // available widgets, not counting custom ones we make in src/cms/widgets,
  // see https://www.netlifycms.org/docs/widgets/
  fields: [
    {
      name: 'submiturl',
      label: 'Submission URL',
      widget: 'string'
    }
  ],
  component: EmailCollector
};
