import {
  CACTUS_100,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpSmallHeadlineMedium,
  PropsWithTheme
} from '@rategravity/own-up-component-library-new';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

export const Headline = styled(OwnUpSmallHeadlineMedium)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
`;

export const Link = styled(GatsbyLink)`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.underlineLinkColors.linkFontColor};
  text-decoration: none;
`;

export const MetadataWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

export const DateWrapper = styled.div`
  display: 'inline';
`;

export const ImageWrapper = styled(OwnUpGridWrapper)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    margin: 0;
  }
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
`;

export const BlogPostWrapper = styled(OwnUpGridItem)`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(10)}px`};
  p {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(7)}px`};
  }
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p > img {
    width: 100%;
  }
  li {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 1)};
  }
  li::before {
    content: '•';
    color: ${CACTUS_100};
    padding-right: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
  }
`;
