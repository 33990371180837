import { OwnUpBodyItalic, OwnUpLargeBody } from '@rategravity/own-up-component-library-new';
import React from 'react';
import { AuthorDetailsBody, AuthorDetailsImage, AuthorDetailsWrapper } from './styled';

export type AuthorProps = {
  name: string;
  image: string;
  bio: string;
};

export interface AuthorJson {
  bios: AuthorProps[];
}

export const AuthorDetails = ({
  authorProps
}: {
  authorProps: AuthorProps | undefined;
}): JSX.Element | null => {
  if (authorProps === undefined) {
    return null;
  }
  const { name, image, bio } = authorProps;
  const imgUrl = typeof image === 'string' ? image.replace(/^(\.\.\/)*static/, '') : image;

  return (
    <AuthorDetailsWrapper>
      {/* TODO: take advantage of gatsby image features */}
      <AuthorDetailsImage src={imgUrl} alt={name} />
      <AuthorDetailsBody>
        <OwnUpLargeBody variant="body1">{name}</OwnUpLargeBody>
        <OwnUpBodyItalic variant="body1">{bio}</OwnUpBodyItalic>
      </AuthorDetailsBody>
    </AuthorDetailsWrapper>
  );
};
