import parse, { DOMNode, domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import { EditorExtension } from '../../components/editor-extension';
import { replaceAnchorTag } from './link-with-tracking';
import { replaceHeadingsMap } from './typography';

const getReplaceHtmlMap = (id: string) => ({
  extension: EditorExtension,
  ...replaceHeadingsMap,
  ...replaceAnchorTag(id)
});

const getReplaceHtmlPlaceHolders = (title: string) => {
  const replaceHtmlMap = getReplaceHtmlMap(title);

  const replaceHtmlPlaceHolders: HTMLReactParserOptions['replace'] = (domNode: DOMNode) => {
    if (domNode instanceof Element && domNode.type === 'tag' && domNode.name in replaceHtmlMap) {
      const tag = domNode.name as keyof typeof replaceHtmlMap;
      const { attribs: props, children } = domNode;
      return replaceHtmlMap[tag](props, domToReact(children, { replace: replaceHtmlPlaceHolders }));
    }
    return domNode;
  };

  return replaceHtmlPlaceHolders;
};

export const processBlogPostFromHtml = (
  html: string | JSX.Element,
  id: string
): ReturnType<typeof parse> =>
  typeof html === 'string' ? parse(html, { replace: getReplaceHtmlPlaceHolders(id) }) : html;

export const processBlogPostFromMarkdown = (
  markdown: string,
  id: string
): ReturnType<typeof processBlogPostFromHtml> => {
  return processBlogPostFromHtml(remark().use(remarkHtml).processSync(markdown).toString(), id);
};
